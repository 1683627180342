<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <h2 id="title-of-block-check">Confirm Email</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" v-if="succeded">
            <p>
              Your email address has been confirmed!
              Please click
              <a
                :href="clientUrl"
              >here</a>
            </p>
          </div>
          <div class="col-sm-12" v-if="!succeded && loaded">
            <div
              class="text-danger"
            >There has been a problem confirming your email, please try again or contact your administrator.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'

export default {
  name: 'ConfirmEmail',
  props: ['query'],
  computed: {
    clientUrl () {
      return process.env.VUE_APP_MAIN_CLIENT
    }
  },
  data () {
    return {
      succeded: false,
      loaded: false
    }
  },
  async mounted () {
    try {
      var tokenResponse = await axios.get('Home/GetToken')
      axios.defaults.headers.common['X-XSRF-TOKEN'] = tokenResponse.data.token

      var response = await axios.post('Account/ConfirmEmail', { UserId: this.query.userId, Token: this.query.code }, { showload: true })

      if (!response.data.succeded) {
        throw new Error('Bad things')
      } else {
        this.succeded = true
      }
    } catch {
      await this.$snapbar.e('Problem Confirming Email')
    } finally {
      this.loaded = true
    }
  },
  methods: {
    async goHome () {
      location.replace(process.env.VUE_APP_MAIN_CLIENT)
    }
  }
}
</script>
